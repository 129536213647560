import {SOFT_NAV_STATE} from './state'

export function interceptNavigationUI() {
  if (typeof navigation === 'undefined') return
  if (typeof NavigateEvent === 'undefined') return
  if (typeof NavigateEvent.prototype?.intercept !== 'function') return

  document.addEventListener(SOFT_NAV_STATE.PROGRESS_BAR.START, () => {
    const finished = new Promise(resolve => {
      document.addEventListener(SOFT_NAV_STATE.PROGRESS_BAR.END, resolve, {once: true})
    })
    navigation.addEventListener(
      'navigate',
      e => {
        if (e.canIntercept) {
          e.intercept({
            scroll: 'manual',
            handler: () => {
              history.replaceState(navigation.currentEntry.getState(), '', location.pathname)
              return finished
            },
          })
        }
      },
      {once: true},
    )
    navigation.navigate(location.href, {
      history: 'replace',
      state: history.state,
    })
  })
}
